<template>
  <div id="header" class="fixed-top align-items-center">
    <ul>
  <li>
    <div class="logo">
      <h1>
        <router-link to="home">
            <img src="../../assets/img/logo/nerdvana_logo.png" class="img-fluid" alt="" style="margin-right: 1px;margin-top: -10px;">
            <span>erdvana</span>
        </router-link>
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
    </div>
  </li>
  <li><a style="margin-top: 3px;" data-parent="#accordion" data-toggle="collapse" href="#product">Product <span><i class="bi bi-chevron-down"></i></span></a></li>
  <li><a href="#services" style="margin-top: 3px;">Why Nerdvana?</a></li>
  <!-- <li><a style="margin-top: 3px;" data-parent="#accordion" data-toggle="collapse" href="#positive">POSitive <span><i class="bi bi-chevron-down"></i></span></a></li>
  <li><a style="margin-top: 3px;" data-parent="#accordion" data-toggle="collapse" href="#wirewolf">Wirewolf <span><i class="bi bi-chevron-down"></i></span></a></li> -->
  <li><a href="products#pricing" style="margin-top: 3px;">Pricing</a></li>
  <!-- <li style="float:right;"><a href="account" style="margin-top: 3px;">Sign up</a></li>
  <li style="float:right"><a href="#about" style="margin-top: 3px;">Login</a></li> -->

        <!-- <nav id="navbar" v-bind:class="(mobile_drawer) ? 'navbar navbar-mobile' : 'navbar'">
          <ul v-if="this.$store.getters.fetchHeader=='main'">
            <li><a class="nav-link" href="#hero" @click="CloseMobileDrawer()">Home</a></li>
            <li><a class="nav-link" href="#about" @click="CloseMobileDrawer()">About</a></li>
            <li><a class="nav-link" href="#services" @click="CloseMobileDrawer()">Services</a></li>
            <li><a class="nav-link" href="#team" @click="CloseMobileDrawer()">Team</a></li>
            <li><a class="nav-link scrollto" href="#contact" @click="CloseMobileDrawer()">Contact Us</a></li>
            <li><a class="nav-link scrollto" href="#products" @click="CloseMobileDrawer()">Products</a></li>
            <li><a href="account" class="btn-get-started">Signup</a></li>
          </ul>
          <ul v-else>
              <li><a class="nav-link" href="products#intro-wirewolfEnterprise" @click="CloseMobileDrawer()">Enterprise</a></li>
              <li><a class="nav-link" href="products#intro-wirewolfLite" @click="CloseMobileDrawer()">Wirewolf</a></li>
          </ul>
          <i @click="OpenMobileDrawer()" v-bind:class="(mobile_drawer) ? 'bi-x bi-list mobile-nav-toggle': 'bi bi-list mobile-nav-toggle'"></i>
        </nav> -->
</ul>
    <div class="panel-group" id="accordion">
      <div class="panel">


        <!-- <div id="wirewolf" class="panel-collapse collapse">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-3">
                <img src="../../assets/img/logo/wirewolf.png" class="img-fluid" alt="" width="50%">
                <p>Wirewolf is the spending solution for forward-thinking teams.</p>
                <a href="products">Overview</a>
              </div>
              <div class="col-sm-3">
                <br>
                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-cart4 circle-icon1"></i> 
                  </div>
                  <div class="col-sm-11">
                <h4>Employee spending cards</h4>
                </div>
                    <br>
                    <span> Virtual and plastic cards, so your team can buy what they need.</span>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-hand-index-thumb circle-icon2"></i> 
                  </div>
                  <div class="col-sm-11">
                    <h4>Out-of-pocket expenses</h4>
                  </div>
                    <br>
                    <span> Centralize all of your business spending, including milage.</span>
                  </div>
            </div>

            <div class="col-sm-3">
                <br>
              <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-receipt circle-icon3"></i> 
                  </div>
                  <div class="col-sm-11">
                <h4>Invoice payments</h4>
                </div>
                    <br>
                    <span> Manage and pay your bills easily.</span>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-android circle-icon4"></i>
                  </div>
                  <div class="col-sm-11">
                    <h4>Apps & Integration</h4>
                  </div>
                    <br>
                    <span> Here are all the apps you can connect to.</span>
                  </div>
              </div>
          </div>
        </div> -->
        
        <!-- <div id="positive" class="panel-collapse collapse">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-3">
                <img src="../../assets/img/logo/positive.png" class="img-fluid" alt="" width="50%">
                <p>POSitive is the spending solution for forward-thinking teams.</p>
                <a href="products">Overview</a>
              </div>
              <div class="col-sm-3">
                <br>
                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-cart4 circle-icon1"></i> 
                  </div>
                  <div class="col-sm-11">
                <h4>Employee spending cards</h4>
                </div>
                    <br>
                    <span> Virtual and plastic cards, so your team can buy what they need.</span>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-hand-index-thumb circle-icon2"></i> 
                  </div>
                  <div class="col-sm-11">
                    <h4>Out-of-pocket expenses</h4>
                  </div>
                    <br>
                    <span> Centralize all of your business spending, including milage.</span>
                  </div>
            </div>

            <div class="col-sm-3">
                <br>
              <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-receipt circle-icon3"></i> 
                  </div>
                  <div class="col-sm-11">
                <h4>Invoice payments</h4>
                </div>
                    <br>
                    <span> Manage and pay your bills easily.</span>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-android circle-icon4"></i>
                  </div>
                  <div class="col-sm-11">
                    <h4>Apps & Integration</h4>
                  </div>
                    <br>
                    <span> Here are all the apps you can connect to.</span>
                  </div>
              </div>
          </div>
        </div> -->

          <div id="product" class="panel-collapse collapse">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-3">
                <img src="../../assets/img/logo/positive_name.png" class="img-fluid" alt="">
                <br>
                <a href="products">Overview</a><br><br>
              </div>

              <div class="col-sm-1"></div>
              <div class="col-sm-5">

                <div class="row">
                  <!-- <div class="col-sm-1">
                  </div> -->
                  <!-- <div class="col-sm-3"> -->
                    <p><img src="../../assets/img/home/product/retail.png" alt="" class="imgfloat"><br>
                      <!-- Get ready to rock retail like a boss with our POSitive POS Retail solution made for Tito’s and Tita’s store owners. Track inventory in real-time, and market to your customers with personalized campaigns. Goodbye boring retail, hello future-forward sales! -->
                      Get ready to rock retail like a boss with our POSitive POS Retail solution made for Tito’s and Tita’s store owners.
                      <br>
                    <a href="products">See features</a>
                    </p>
                <!-- </div> -->
                </div>


                <div class="row">
                  <!-- <div class="col-sm-1">
                  </div> -->
                  <!-- <div class="col-sm-3">
                    <p style="float:left"><img src="../../assets/img/home/product/hotel.png" alt="" style="width:200%;height:80%;">qweqweeqweqwewqeqw</p>
                </div> -->
                <p><img src="../../assets/img/home/product/hotel.png" alt="" class="imgfloat"><br>
                    <!-- Welcome to the future of hospitality! Our POSitive Motel/Hotel solution offering a seamless and personalized experience like never before. From booking to check-out, you'll be able to manage everything and making your life easier with our advanced technology. -->
                    Welcome to the future of hospitality! Our POSitive Motel/Hotel solution.
                    <br>
                    <a href="motel">See features</a>
                  </p>
                </div>

                

                <div class="row">
                  <!-- <div class="col-sm-1">
                  </div> -->
                  <!-- <div class="col-sm-3"> -->
                  <p><img src="../../assets/img/home/product/Retail-Resto-V2.png" alt="" class="imgfloat"><br>
                    <!-- Taste success with our POSitive POS Restaurant solution made for Titos and Titas. Elevate your dining experience with online ordering, real-time kitchen displays, and table management that makes running a restaurant a piece of (delicious) cake. Let's get cookin'! -->
                    Taste success with our POSitive POS Restaurant solution made for Titos and Titas.
                    <br>
                    <a href="restoretail">See features</a>
                  </p>
                <!-- </div> -->
                </div>

            </div>


            <!-- <div class="col-sm-3">
                <div class="row">
                  <div class="col-sm-12">
                    <p>Welcome to the future of hospitality! Our POSitive Motel/Hotel solution offering a seamless and personalized experience like never before. From booking to check-out, you'll be able to manage everything and making your life easier with our advanced technology.</p><br><br>
                </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <p>Get ready to rock retail like a boss with our POSitive POS Retail solution made for Tito’s and Tita’s store owners. Track inventory in real-time, and market to your customers with personalized campaigns. Goodbye boring retail, hello future-forward sales!</p><br><br><br>
                </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <p>Taste success with our POSitive POS Restaurant solution made for Titos and Titas. Elevate your dining experience with online ordering, real-time kitchen displays, and table management that makes running a restaurant a piece of (delicious) cake. Let's get cookin'!</p>
                </div>
                </div>
            </div> -->

            <!-- <div class="col-sm-3">
                <br>
              <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-receipt circle-icon3"></i> 
                  </div>
                  <div class="col-sm-11">
                <h4>Invoice payments</h4>
                </div>
                    <br>
                    <span> Manage and pay your bills easily.</span>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-1">
                    <i class="bi bi-android circle-icon4"></i>
                  </div>
                  <div class="col-sm-11">
                    <h4>Apps & Integration</h4>
                  </div>
                    <br>
                    <span> Here are all the apps you can connect to.</span>
                  </div>
              </div> -->
          </div>
        </div>


      </div>
      </div>
    
  </div>

</template>

<script>
export default {
  name: 'header',
  data(){
    return {
        mobile_drawer: false
    }
  },
  props: {
    msg: String
  },
  methods: {
    OpenMobileDrawer(){

      if(this.mobile_drawer){
        this.mobile_drawer = false;
        // event.target.classList.toggle('bi-x');
      }
      else{
        this.mobile_drawer = true;
        // event.target.classList.toggle('bi-x');
      }

    },
    CloseMobileDrawer(){
        this.mobile_drawer = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
}

li {
  float: left;
}

li a {
  display: block;
  color: rgb(5, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: rgb(255, 255, 255);
}

.active {
  background-color: #707372;
}

.circle-icon1 {
    background: #6ceae8;
    padding:10px;
    border-radius: 45%;
}
.circle-icon2 {
    background: #a6e162;
    padding:10px;
    border-radius: 45%;
}
.circle-icon3 {
    background: #da7bf4;
    padding:10px;
    border-radius: 45%;
}
.circle-icon4 {
    background: #e1bb62;
    padding:10px;
    border-radius: 45%;
}
.imgfloat{
  float: left;
  width:100px;
  height:100px;
  margin-left:15px;
}

</style>
