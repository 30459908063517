import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import vuex from './data/data';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

const app = createApp(App);

app.use(router);
app.use(vuex);
app.use(VueSweetalert2);
app.use(BootstrapIconsPlugin);
app.mount('#app');
