<template>
    <div>
      <!-- ======= Hero Section ======= -->
      <section id="hero" class="d-flex align-items-center">
  
  <div class="container">
    <div class="row">
      <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
        <h1 data-aos="fade-up"> <font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font> Motel</h1>
        <h2 data-aos="fade-up" data-aos-delay="400">Welcome to the future of hospitality! Our POSitive Motel/Hotel solution offering a seamless and personalized experience like never before. From booking to check-out, you'll be able to manage everything and making your life easier with our advanced technology.
        </h2>
      </div>
      <div class="col-lg-6 hero-img order-1" data-aos="fade" data-aos-delay="200">
        <center>
          <!-- <img src="../../assets/img/logo/positive_name.png" style="max-width: 400px;" class="img-fluid animated" alt=""> -->
          <img src="../../assets/img/home/product/hotel-icon.png" style="max-width: 400px;" class="img-fluid leftright"  alt="">
        </center>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- <p>The First <strong>BIR-Accredited</strong> Cloud-based Android POS System in the <strong>Philippines</strong></p> -->
      </div>
    </div>
  </div>
  
  </section>
      <!-- End Hero -->
  
  
     
      <main id="main">
        <!-- ======= wirewolf enterprise Section ======= -->
  
        <!-- RETAIL -->
        <div id="enterprise-retail">
          &nbsp;
        </div>
        <section id="section-enterprise-retail" class="about">
          <div class="container">
            <div class="row content">
                  <div class="col-md-6">
                    <ul>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Cloud.png" alt="" class="featureicons"><br>  
                        <b> Cloud database & also Working Offline: </b>
                        POSitive is a cloud based Point of Sale (POS) solution it allows to operate both online and offline, depending on the availability of an internet connections.
                      </li> <br>  <br>  
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Real-time-sales.png" alt="" class="featureicons"><br>  
                          <b> Real-time sales update: </b>
                          POSitive provides up-to-date information on sales, allowing the user to see how much revenue the business is generating at any given moment. This data can be critical in decision-making.
                      </li> <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Real-time-reports.png" alt="" class="featureicons"><br>  
                          <b> Real-time reports + analytics: </b>
                          POSitive provides real-time access to business data, allowing the user to generate reports and analyze sales trends quickly and to make decisions and improve business operations.
                      </li>  <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Real-time-room.png" alt="" class="featureicons"> <br> 
                          <b> Real-time room and room performance monitoring: </b>
                          POSitive enables the user to monitor the status and performance of rooms in real-time. It allows the user to track occupancy levels, room maintenance issues, and housekeeping schedules. 
                      </li> <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Real-time-employee.png" alt="" class="featureicons">
                          <br>  
                          <b> Real-time employee performance monitoring: </b>
                          POSitive enables the user to monitor employee performance in real-time. It allows the user to track attendance, sales, and other key performance indicators. 
                      </li> <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Room.png" alt="" class="featureicons">
                          <br>  
                          <b> Room & guest management through POS or Mobile: </b>
                          POSitive enables the user to manage guest rooms and information. It allows the user to check guests in and out, view room availability, and manage service requests. 
                      </li> <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Online-booking-integrated.png" alt="" class="featureicons">
                          <br>  
                          <b> Online booking integrated: </b>
                          POSitive enables guests to book rooms online through an integrated system. It allows guests to view availability, select a room type, and make a reservation from anywhere.
                      </li> <br><br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/POS-integrated-Online-ordering.png" alt="" class="featureicons">
                          <br>  
                          <b> Online ordering through QR or Website: </b>
                          POSitive allows guests to order food and drinks through  mobile or website using a QR code. It enables the guests to order and pay for their orders without the need for staff intervention.
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul>
  
                      <li>
                          <img src="../../assets/img/icons/revised-icons/KDS-Integrated.png" alt="" class="featureicons">   <br> 
                          <b> KDS (Kitchen Display System): </b>
                          It enables the user to manage and display kitchen orders through a digital system. So the staff can  view and manage orders in real-time, reducing order errors and improving efficiency.
                      </li>  <br> <br>
                      
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Centralized.png" alt="" class="featureicons">  <br> 
                          <b> Centralized multi-branch management: </b>
                          POSitive provides a centralized system for managing multiple branches on a single platform. This can include inventory & employee management and financial reporting.
                      </li> <br> <br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Audit-trail-tracking-POS.png" alt="" class="featureicons">  <br> 
                          <b> Audit trail: </b>
                          POSitive has a record of all actions or processes  taken by the POS  and back-office.  It provides information of who did what and when, to prevent fraudulent activity and improve accountability.
                      </li> <br> <br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Inventory-Management.png" alt="" class="featureicons"> <br> 
                          <b> Inventory Management: </b>
                          POSitive allows the user to manage tracking stock levels and generating reports. It can automatically update stocks when items are sold, to maintain accurate stock levels and avoid stockouts.
                      </li> <br> <br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Printer-cash-drawer.png" alt="" class="featureicons">  <br> 
                          <b> Printer and cash drawer integrated: </b>
                        POSitive allows the POS system to be integrated with printers and cash drawers, making it easier to print receipts and handle cash transactions.
                      </li>  <br> <br>
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Discount-management.png" alt="" class="featureicons"> <br> 
                          <b> Discount management: </b>
                        POSitive allows the user to set up and manage different types of discounts, such as senior & PWD discounts, percentage and fixed amount discounts, making it easier to boost sales.
                      </li> <br>  <br>  
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Payment-management.png" alt="" class="featureicons"> <br> 
                          <b> Payment management: </b>
                          POSitive enables the user to accept different payment methods, such as cash, credit cards, mobile payments, or other payment options. It can help reduce errors and enhance customer experience.
                      </li> <br>  <br>  
                        <li>
                          <img src="../../assets/img/icons/revised-icons/Payment-management2.png" alt="" class="featureicons"> <br> 
                          <b> Other features: </b>
                          POSitive is already accredited by Rockwell Malls.
                          We also assist with BIR POS Permit-to-Use (PTU) for businesses registered in all RDOs across the Philippines. Be able to print Official Receipts through the POSitive POS system to be in compliance with the Bureau of Internal Revenue.

                      </li> <br>  <br>  
                    </ul>
                  </div>
                <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
        </section>
  
  
        
  
  
  
  
        <!-- RESTAURANT -->
        <!-- <div id="enterprise-restaurant">
          &nbsp;
        </div>
        <section id="section-enterprise-restaurant" class="about section-bg">
          <div class="container">
            <div class="row content">
              <div class="col-lg-5">
                <div class="prodImgContainer">
                  <img src="../../assets/img/home/product/resto.png" style="width: 100%;" alt="">
                </div>
              </div>
              <div class="col-lg-7">
                <center>
                  <label style="font-weight: bold;font-size: 40px;">Restaurant</label>
                </center>
                <br><br>
                <div class="row">
                  <div class="col-md-6">
                    <ul>
                      <li><i class="ri-check-double-line"></i> Standalone and Cloud-based System</li>
                      <li><i class="ri-check-double-line"></i> Dashboard</li>
                      <li><i class="ri-check-double-line"></i> Procurement System</li>
                      <li><i class="ri-check-double-line"></i> Panoramic Inventory</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li><i class="ri-check-double-line"></i> Online Ordering System</li>
                      <li><i class="ri-check-double-line"></i> Content Management System</li>
                      <li><i class="ri-check-double-line"></i> Financial Reports</li>
                      <li><i class="ri-check-double-line"></i> Accounting System</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row content">
              <div class="col-lg-12">
                &nbsp;
                <p style="visibility: hidden">
                  You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
                </p>
                <center>
                  <div>
                    <a href="appointment?type=restaurant&categ=enterprise&product=wirewolf" class="btn-get-started">Set appointment</a>
                  </div>
                </center>
              </div>
            </div>
  
          </div>
        </section> -->
        <!-- End wirewolf enterprise Section -->
  
        <!-- ======= intro wirewolf Section ======= -->
        <!-- <div id="intro-wirewolfLite">
          &nbsp;
        </div>
        <section id="section-intro-wirewolfLite" class="about wirewolf-bg">
          <div class="container">
  
            <div class="section-title" data-aos="fade-up">
              <h2>Wirewolf</h2>
              <p>Our <strong>Wirewolf</strong> version are currently in development stage. A brand new model of POS which focuses on mobility and good accessablity of all module included on POS for owners and cashier everywhere  and anytime within or outside store, a version that you can run in any android platform of your choice, especially made for <strong>kids, tito's and tita's</strong></p>
            </div>
  
            <div class="row content hide">
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
                <p>
                  <strong>Nerdvana</strong> was established in <strong>2017</strong>, managed and collaborated by professionals with years of experience in business solutions and innovations.
                </p>
                <ul>
                  <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                  <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                  <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
                </ul>
              </div>
             
            </div>
  
          </div>
          <center>
          <div data-aos="fade-up" data-aos-delay="150">
                <a href="https://play.google.com/store/apps/details?id=com.nerdvana.corp.wirewolflite" class="btn-download">Download here</a>
          </div>
          </center>
        </section> -->
        <!-- End ntro wirewolf Section -->
  
         <!-- ======= Quotation Section ======= -->
         <section id="quotation" class="d-flex align-items-center">
  
  <div class="container">
    <div class="row">
      <div class="col-lg-8 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
        <!-- <h1 data-aos="fade-up"> <font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font> Motel</h1> -->
        <h2 data-aos="fade-up" data-aos-delay="400">
        The POSitive POS Solution for Enterprise is designed for larger businesses such as restaurants, retail shops, and hotels. This reliable system offers advanced features such as inventory management, and sales analysis. With its powerful hardware and customizable software, the POSitive POS System for Enterprise will streamline your business operations and increase your bottom line.
        </h2>
      </div>
      <div class="col-lg-4 hero-img order-1" data-aos="fade" data-aos-delay="200">
        <center>
          <!-- <img src="../../assets/img/logo/positive_name.png" style="max-width: 400px;" class="img-fluid animated" alt=""> -->
          <img src="../../assets/img/home/product/enterprise.png" style="max-width: 400px;" class="img-fluid"  data-aos="fade" data-aos-delay="200"  alt="">
        </center>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- <p>The First <strong>BIR-Accredited</strong> Cloud-based Android POS System in the <strong>Philippines</strong></p> -->


        <center>
                <div>
                  <a href="appointment?type=retail&categ=enterprise&product=wirewolf" class="btn-get-started">Request a Quote</a>
                </div>
              </center>
      </div>
    </div>
  </div>
  
  </section>
        <!-- End Quotation Section -->
  
  
         <!-- ======= Contact Section ======= -->
         <div id="contact" class="section-bg">
          &nbsp;
        </div>
        <section id="contact-section" class="contact">
          <div class="container">
  
            <div class="section-title" data-aos="fade-up">
              <h2>Contact Us</h2>
            </div>
  
            <div class="row">
  
              <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                <div class="contact-about">
                  <h3>Nerdvana</h3>
                  <p>We are here to support and protect your business, in order to do this we simplify the way you do your business.</p>
                  <div class="social-links">
                    <a href="https://www.tiktok.com/@nerdvana_17" target="_blank" class="twitter">
                        <BIconTiktok class="mb-2" />
                    </a>
                    <a href="https://www.facebook.com/NerdvanaPOS" target="_blank" class="facebook">
                        <BIconFacebook class="mb-2" />
                    </a>
                    <a href="https://www.instagram.com/nerdvana_pos/?hl=en" target="_blank" class="instagram">
                        <BIconInstagram class="mb-2" />
                    </a>
                    <!-- <a href="#" target="_blank" class="linkedin">
                        <BIconLinkedin class="mb-2" />
                    </a> -->
                  </div>
                </div>
              </div>
  
              <div class="col-lg-6 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                <div class="info">
                  <div>
                    <i class="ri-map-pin-line"></i>
                    <p>Canley #1 1100 Pasig, Philippines.</p>
                  </div>
  
                  <div>
                    <i class="ri-mail-send-line"></i>
                    <p>sales@nerdvana.com.ph</p>
                  </div>
  
                  <div>
                    <i class="ri-phone-line"></i>
                    <p>(+63) 917-634-2243</p>
                  </div>
  
                </div>
              </div>
  
              <!-- <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
                
              </div> -->
  
            </div>
  
          </div>
        </section><!-- End Contact Section -->
        
  
        <!-- ======= wirewolf enterprise Section ======= -->
        <!-- <section>
          <div class="row">
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
                <br>
                <center>
                  <label style="font-weight: bold;font-size: 40px;">RETAIL</label>
                  <div class="comingSoftwares">
                    <div class="prodImgContainer">
                      <img src="../../assets/img/home/product/retail.png" style="width: 100%;" alt="">
                    </div>
                  </div>
                </center>
                <br>
              </div>
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="600">
                <br>
                <center>
                  <label style="font-weight: bold;font-size: 40px;">RESTAURANT</label>
                  <div class="comingSoftwares">
                    <div class="prodImgContainer">
                      <img src="../../assets/img/home/product/resto.png" style="width: 100%;" alt="">
                    </div>
                  </div>
                </center>
                <br>
            </div>
          </div>
        </section> -->
  
  
        <!-- <div class="">
  
          <div id="lite-retail">
            &nbsp;
          </div>
          <section id="section-lite-retail" class="about">
            <div class="container">
              <div class="row content">
                <div class="col-lg-5">
                  <div class="prodImgContainer">
                    <img src="../../assets/img/home/pos.png" style="width: 100%;" alt="">
                  </div>
                </div>
                <div class="col-lg-7">
                  <center>
                    <label style="font-weight: bold;font-size: 40px;">Retail</label>
                  </center>
                  <ul>
                    <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                    <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                    <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
                  </ul>
                </div>
              </div>
              <div class="row content">
                <div class="col-lg-12">
                  <p style="visibility: hidden">
                    You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
                  </p>
                  <center>
                    <div>
                      <a href="plans?type=retail&categ=lite&product=wirewolf" class="btn-get-started">View Plan</a>
                    </div>
                  </center>
                </div>
              </div>
  
            </div>
          </section>
  
          <div id="lite-restaurant">
            &nbsp;
          </div>
          <section id="section-lite-restaurant" class="about section-bg">
            <div class="container">
              <div class="row content">
                <div class="col-lg-5">
                  <div class="prodImgContainer">
                    <img src="../../assets/img/home/pos.png" style="width: 100%;" alt="">
                  </div>
                </div>
                <div class="col-lg-7">
                  <center>
                    <label style="font-weight: bold;font-size: 40px;">Restaurant</label>
                  </center>
                  <ul>
                    <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                    <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                    <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
                  </ul>
                </div>
              </div>
              <div class="row content">
                <div class="col-lg-12">
                  <p style="visibility: hidden">
                    You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
                  </p>
                  <center>
                    <div>
                      <a href="plans?type=restaurant&categ=lite&product=wirewolf" class="btn-get-started">View Plan</a>
                    </div>
                  </center>
                </div>
              </div>
  
            </div>
          </section>
  
        </div> -->
        <!-- End wirewolf lite Section -->
  
      </main>
      <!-- End #main -->
      
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'motel',
    props: {
      msg: String
    },
    data(){
      return {
        images: {
          prod:{
            motel: "pos.png",
            resto: "pos.png",
            retail: "pos.png",
            lite: "pos.png"
          }
        }
      }
    },
    created(){
      this.$store.state.header = "motel";
    },
    mounted(){
      console.log(this.$store.getters.fetchHeader);
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
    /* Defualt */
    .member{
      width: 100%;
    }
    
    .member .member-img img{
      /* width: 100%; */
      height: 250px !important;
    }
  
    .features .icon-box{
      display: block !important;
      /* margin: auto;
      width: 100% ; */
      text-align: center;
      background: transparent !important;
    }
  
    .icon-box img{
      width: 100%;
      height: 100%;
      /* position: relative; */
      /* top: -5px;
      left: -5px; */
      /* overflow: auto; */
    }
  
    .imgcontainer{
      /* position: relative; */
      margin: auto;
      margin-bottom: 20px;
      width: 300px;
      height: 300px;
      border-radius: 10%;
      padding: 10px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
  
    .text-gradiant{
        /* Fallback: Set a background color. */
      background-color: red;
      font-weight: bold;
      /* Create the gradient. */
      background-image: linear-gradient(to right, #f54001 , #efb923);
      
      /* Set the background size and repeat properties. */
      background-size: 100%;
      background-repeat: repeat;
  
      /* Use the text as a mask for the background. */
      /* This will show the gradient as a text color rather than element bg. */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  
    .btn-get-started{
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 30px;
      border-radius: 50px;
      transition: 0.5s;
      color: #3498db;
      border: 2px solid #3498db;
    }
  
    .btn-download{
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 30px;
      border-radius: 50px;
      transition: 0.5s;
      color: #ffffff;
      border: 2px solid #ffffff;
    }
  
    .wirewolf-bg{
      background-image: linear-gradient(to right, #f54001 , #efb923) !important;
      color: white !important;
      /* background: transparent !important; */
    }
  
    .featureicons{
      height: 130px;
      width: 130px;
    float: left;
    /* margin-left:15px; */
    }
  
    .leftright{
      position: relative;
      animation: mymove 5s infinite;
      animation-direction:alternate-reverse;
    }
  
    @keyframes mymove {
    from {left: 200px;}
    to {left: 0px;}
  }
  
  </style>
  