<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">

      <div class="container">
        <div class="row">
          <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up"> <font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font> Retail</h1>
            <h2 data-aos="fade-up" data-aos-delay="400">Get ready to rock retail like a boss with our POSitive POS Retail solution made for Tito’s and Tita’s store owners. Track inventory in real-time, and market to your customers with personalized campaigns. Goodbye boring retail, hello future-forward sales!
            </h2>
          </div>
          <div class="col-lg-6 hero-img order-1" data-aos="fade" data-aos-delay="200">
            <center>
              <!-- <img src="../../assets/img/logo/positive_name.png" style="max-width: 400px;" class="img-fluid animated" alt=""> -->
              <img src="../../assets/img/home/product/retail-icon.png" style="max-width: 500px;" class="img-fluid leftright"  alt="">
            </center>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <!-- <p>The First <strong>BIR-Accredited</strong> Cloud-based Android POS System in the <strong>Philippines</strong></p> -->
          </div>
        </div>
      </div>

    </section>
    <!-- End Hero -->


   
    <main id="main">
      <!-- ======= wirewolf enterprise Section ======= -->

      <!-- RETAIL -->
      <div id="enterprise-retail">
        &nbsp;
      </div>
      <section id="section-enterprise-retail" class="about">
        <div class="container">
          <div class="row content">
                <div class="col-md-6">
                  <ul>
                    <li>
                      <img src="../../assets/img/icons/revised-icons/Cloud.png" alt="" class="featureicons"><br>  
                      <b> Cloud database & also Working Offline: </b>
                      POSitive is a cloud based Point of Sale (POS) solution it allows to operate both online and offline, depending on the availability of an internet connections.
                    </li> <br>  <br>  
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Real-time-sales.png" alt="" class="featureicons"><br>  
                        <b> Real-time sales update: </b>
                        POSitive provides up-to-date information on sales, allowing the user to see how much revenue the business is generating at any given moment. This data can be critical in decision-making.
                    </li> <br><br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Sell-smartphone-tablet.png" alt="" class="featureicons"><br>  
                        <b> Sell from an android smartphone or tablet: </b>
                        POSitive lets you access the system from your device, which means you can complete transactions remotely. This is useful when you're selling products or services on the go.
                    </li> <br><br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Real-time-reports.png" alt="" class="featureicons"><br>  
                        <b> Real-time reports + analytics: </b>
                        POSitive provides real-time access to business data, allowing the user to generate reports and analyze sales trends quickly and to make decisions and improve business operations.
                    </li>  <br><br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Printer-cash-drawer.png" alt="" class="featureicons"> <br> 
                        <b> Printer and cash drawer integrated: </b>
                        POSitive allows the POS system to be integrated with printers and cash drawers, making it easier to print receipts and handle cash transactions.
                    </li> <br><br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Printed-electronic.png" alt="" class="featureicons">
                        <br>  
                        <b> Printed or electronic receipts: </b>
                        POSitive allows you to provide your customers with receipts, whether they prefer a printed receipt or an electronic copy sent to their email, POSitive has got you covered. This feature can be a real game-changer when it comes to customer service.
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>

                    <li>
                        <img src="../../assets/img/icons/revised-icons/Employee-management.png" alt="" class="featureicons">   <br> 
                        <b> Employee management: </b>
                        POSitive can easily track and manage employee data, it includes Access Rights that manage access to sensitive information and functions, ensuring that your data is always secure.
                    </li>  <br> <br>
                    
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Audit-trail-tracking-POS.png" alt="" class="featureicons">  <br> 
                        <b> Open transaction – Create, Pause and Resume orders: </b>
                        POSitive enables the user to create and save orders in the POS system without processing payment immediately. It allows the user to edit the order before finalizing it.
                    </li> <br> <br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Payment-management.png" alt="" class="featureicons">  <br> 
                        <b> Multiple payment methods: </b>
                        POSitive enables the user to accept different payment methods, such as cash, credit cards, mobile payments, or other payment options. It can help reduce errors and enhance customer experience.
                    </li> <br> <br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Discount-management.png" alt="" class="featureicons"> <br> 
                        <b> Discount management: </b>
                        POSitive allows the user to set up and manage different types of discounts, such as senior & PWD discounts, percentage and fixed amount discounts, making it easier to boost sales.
                    </li> <br> <br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Real-time-reports.png" alt="" class="featureicons">  <br> 
                        <b> Automated Daily sales report: </b>
                        POSitive can save you time and effort, you can quickly analyze your sales data and identify any  issues that need to be addressed without having to manually sort through data and numbers.
                    </li>  <br> <br>
                      <li>
                        <img src="../../assets/img/icons/revised-icons/Inventory-Management.png" alt="" class="featureicons"> <br> 
                        <b> Inventory Management: </b>
                        POSitive allows the user to manage the inventory of the business, including tracking stock levels and generating reports. It can automatically update stock when items are sold, making it easier to maintain accurate stock levels and avoid stockouts.
                    </li> <br>  
                  </ul>
                </div>
              <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </section>






      <!-- RESTAURANT -->
      <!-- <div id="enterprise-restaurant">
        &nbsp;
      </div>
      <section id="section-enterprise-restaurant" class="about section-bg">
        <div class="container">
          <div class="row content">
            <div class="col-lg-5">
              <div class="prodImgContainer">
                <img src="../../assets/img/home/product/resto.png" style="width: 100%;" alt="">
              </div>
            </div>
            <div class="col-lg-7">
              <center>
                <label style="font-weight: bold;font-size: 40px;">Restaurant</label>
              </center>
              <br><br>
              <div class="row">
                <div class="col-md-6">
                  <ul>
                    <li><i class="ri-check-double-line"></i> Standalone and Cloud-based System</li>
                    <li><i class="ri-check-double-line"></i> Dashboard</li>
                    <li><i class="ri-check-double-line"></i> Procurement System</li>
                    <li><i class="ri-check-double-line"></i> Panoramic Inventory</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li><i class="ri-check-double-line"></i> Online Ordering System</li>
                    <li><i class="ri-check-double-line"></i> Content Management System</li>
                    <li><i class="ri-check-double-line"></i> Financial Reports</li>
                    <li><i class="ri-check-double-line"></i> Accounting System</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row content">
            <div class="col-lg-12">
              &nbsp;
              <p style="visibility: hidden">
                You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
              </p>
              <center>
                <div>
                  <a href="appointment?type=restaurant&categ=enterprise&product=wirewolf" class="btn-get-started">Set appointment</a>
                </div>
              </center>
            </div>
          </div>

        </div>
      </section> -->
      <!-- End wirewolf enterprise Section -->

      <!-- ======= intro wirewolf Section ======= -->
      <!-- <div id="intro-wirewolfLite">
        &nbsp;
      </div>
      <section id="section-intro-wirewolfLite" class="about wirewolf-bg">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Wirewolf</h2>
            <p>Our <strong>Wirewolf</strong> version are currently in development stage. A brand new model of POS which focuses on mobility and good accessablity of all module included on POS for owners and cashier everywhere  and anytime within or outside store, a version that you can run in any android platform of your choice, especially made for <strong>kids, tito's and tita's</strong></p>
          </div>

          <div class="row content hide">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
              <p>
                <strong>Nerdvana</strong> was established in <strong>2017</strong>, managed and collaborated by professionals with years of experience in business solutions and innovations.
              </p>
              <ul>
                <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
              </ul>
            </div>
           
          </div>

        </div>
        <center>
        <div data-aos="fade-up" data-aos-delay="150">
              <a href="https://play.google.com/store/apps/details?id=com.nerdvana.corp.wirewolflite" class="btn-download">Download here</a>
        </div>
        </center>
      </section> -->
      <!-- End ntro wirewolf Section -->

       <!-- ======= Pricing Section ======= -->
       <div id="pricing"  class="section-bg">
      <section id="pricing-section" class="pricing section-bg">
        <div class="container">

          <!-- <div class="section-title">
            <h2>Pricing</h2>
            <p>Sit sint consectetur velit nemo qui impedit suscipit alias ea</p>
          </div> -->

          <h1><font size="8" color="gray">POS</font><font size="8" color="#535353">itive</font> Mobile POS Solution Subscription Plans</h1>
          <div class="row">

            <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Free</h3>
                <h4><sup>₱</sup>0<span> <br> Per month</span></h4>
                <h5>Get the basic plan without spending a dime. It's the perfect starting point for businesses on a tight budget.</h5>
                <ul style="display: inline-block;text-align: left; ">
                  <li><i class="ri-checkbox-circle-fill"></i>Basic Reports And Analytics</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Employee Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>POS Transactions</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Advanced Reports and Analytics</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Back Office</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Cash Drawer</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Inventory Management</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Print </li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Receipt Printing</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Shift Management</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>Unlimited Users</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>2 FREE MONTHS</li>
                  <!-- <li class="na">Massa ultricies mi</li> -->
                </ul>
                <div class="btn-wrap">
                  <a href="https://play.google.com/store/apps/details?id=com.nerdvana.corp.wirewolflite&hl=en" class="btn-buy">Install Now</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Monthly</h3>
                <h4><sup>₱</sup>1,200<span> <br> Per month</span></h4>
                <h5>This plan is perfect for those who want to take their business to the next level without having a long commitment.</h5>
                <ul style="display: inline-block;text-align: left; ">
                  <li><i class="ri-checkbox-circle-fill"></i>Basic Reports And Analytics</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Employee Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>POS Transactions</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Advanced Reports and Analytics</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Back Office</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Cash Drawer</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Inventory Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Print </li>
                  <li><i class="ri-checkbox-circle-fill"></i>Receipt Printing</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Shift Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Unlimited Users</li>
                  <li><i class="ri-checkbox-circle-fill" style="color:gray"></i>2 FREE MONTHS</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe Now</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Annual</h3>
                <h4><sup>₱</sup>12,000 <br><span> With Free 2 months</span></h4>
                <h5>Want to save money and get even more features? Sign up for the annual plan and get two months free!</h5>
                <ul style="display: inline-block;text-align: left; ">
                  <li><i class="ri-checkbox-circle-fill"></i>Basic Reports And Analytics</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Employee Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>POS Transactions</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Advanced Reports and Analytics</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Back Office</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Cash Drawer</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Inventory Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Print </li>
                  <li><i class="ri-checkbox-circle-fill"></i>Receipt Printing</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Shift Management</li>
                  <li><i class="ri-checkbox-circle-fill"></i>Unlimited Users</li>
                  <li><i class="ri-checkbox-circle-fill"></i>2 FREE MONTHS</li>
                  <!-- <li class="na">Massa ultricies mi</li> -->
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe Now</a>
                </div>
              </div>
            </div>
          </div>

          </div>

        </div>
      </section>
    </div>
      <!-- End Pricing Section -->


       <!-- ======= Contact Section ======= -->
       <div id="contact" class="section-bg">
        &nbsp;
      </div>
      <section id="contact-section" class="contact">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Contact Us</h2>
          </div>

          <div class="row">

            <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="contact-about">
                <h3>Nerdvana</h3>
                <p>We are here to support and protect your business, in order to do this we simplify the way you do your business.</p>
                <div class="social-links">
                  <a href="https://www.tiktok.com/@nerdvana_17" target="_blank" class="twitter">
                      <BIconTiktok class="mb-2" />
                  </a>
                  <a href="https://www.facebook.com/NerdvanaPOS" target="_blank" class="facebook">
                      <BIconFacebook class="mb-2" />
                  </a>
                  <a href="https://www.instagram.com/nerdvana_pos/?hl=en" target="_blank" class="instagram">
                      <BIconInstagram class="mb-2" />
                  </a>
                  <!-- <a href="#" target="_blank" class="linkedin">
                      <BIconLinkedin class="mb-2" />
                  </a> -->
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
              <div class="info">
                <div>
                  <i class="ri-map-pin-line"></i>
                  <p>Canley #1 1100 Pasig, Philippines.</p>
                </div>

                <div>
                  <i class="ri-mail-send-line"></i>
                  <p>sales@nerdvana.com.ph</p>
                </div>

                <div>
                  <i class="ri-phone-line"></i>
                  <p>(+63) 917-634-2243</p>
                </div>

              </div>
            </div>

            <!-- <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
              
            </div> -->

          </div>

        </div>
      </section><!-- End Contact Section -->
      

      <!-- ======= wirewolf enterprise Section ======= -->
      <!-- <section>
        <div class="row">
          <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
              <br>
              <center>
                <label style="font-weight: bold;font-size: 40px;">RETAIL</label>
                <div class="comingSoftwares">
                  <div class="prodImgContainer">
                    <img src="../../assets/img/home/product/retail.png" style="width: 100%;" alt="">
                  </div>
                </div>
              </center>
              <br>
            </div>
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="600">
              <br>
              <center>
                <label style="font-weight: bold;font-size: 40px;">RESTAURANT</label>
                <div class="comingSoftwares">
                  <div class="prodImgContainer">
                    <img src="../../assets/img/home/product/resto.png" style="width: 100%;" alt="">
                  </div>
                </div>
              </center>
              <br>
          </div>
        </div>
      </section> -->


      <!-- <div class="">

        <div id="lite-retail">
          &nbsp;
        </div>
        <section id="section-lite-retail" class="about">
          <div class="container">
            <div class="row content">
              <div class="col-lg-5">
                <div class="prodImgContainer">
                  <img src="../../assets/img/home/pos.png" style="width: 100%;" alt="">
                </div>
              </div>
              <div class="col-lg-7">
                <center>
                  <label style="font-weight: bold;font-size: 40px;">Retail</label>
                </center>
                <ul>
                  <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                  <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                  <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
                </ul>
              </div>
            </div>
            <div class="row content">
              <div class="col-lg-12">
                <p style="visibility: hidden">
                  You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
                </p>
                <center>
                  <div>
                    <a href="plans?type=retail&categ=lite&product=wirewolf" class="btn-get-started">View Plan</a>
                  </div>
                </center>
              </div>
            </div>

          </div>
        </section>

        <div id="lite-restaurant">
          &nbsp;
        </div>
        <section id="section-lite-restaurant" class="about section-bg">
          <div class="container">
            <div class="row content">
              <div class="col-lg-5">
                <div class="prodImgContainer">
                  <img src="../../assets/img/home/pos.png" style="width: 100%;" alt="">
                </div>
              </div>
              <div class="col-lg-7">
                <center>
                  <label style="font-weight: bold;font-size: 40px;">Restaurant</label>
                </center>
                <ul>
                  <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                  <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                  <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
                </ul>
              </div>
            </div>
            <div class="row content">
              <div class="col-lg-12">
                <p style="visibility: hidden">
                  You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your operations according to your data. No need to do checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
                </p>
                <center>
                  <div>
                    <a href="plans?type=restaurant&categ=lite&product=wirewolf" class="btn-get-started">View Plan</a>
                  </div>
                </center>
              </div>
            </div>

          </div>
        </section>

      </div> -->
      <!-- End wirewolf lite Section -->

    </main>
    <!-- End #main -->
    
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  </div>
</template>

<script>
export default {
  name: 'products',
  props: {
    msg: String
  },
  data(){
    return {
      images: {
        prod:{
          motel: "pos.png",
          resto: "pos.png",
          retail: "pos.png",
          lite: "pos.png"
        }
      }
    }
  },
  created(){
    this.$store.state.header = "products";
  },
  mounted(){
    console.log(this.$store.getters.fetchHeader);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* Defualt */
  .member{
    width: 100%;
  }
  
  .member .member-img img{
    /* width: 100%; */
    height: 250px !important;
  }

  .features .icon-box{
    display: block !important;
    /* margin: auto;
    width: 100% ; */
    text-align: center;
    background: transparent !important;
  }

  .icon-box img{
    width: 100%;
    height: 100%;
    /* position: relative; */
    /* top: -5px;
    left: -5px; */
    /* overflow: auto; */
  }

  .imgcontainer{
    /* position: relative; */
    margin: auto;
    margin-bottom: 20px;
    width: 300px;
    height: 300px;
    border-radius: 10%;
    padding: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }

  .text-gradiant{
      /* Fallback: Set a background color. */
    background-color: red;
    font-weight: bold;
    /* Create the gradient. */
    background-image: linear-gradient(to right, #f54001 , #efb923);
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  .btn-get-started{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #3498db;
    border: 2px solid #3498db;
  }

  .btn-download{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #ffffff;
    border: 2px solid #ffffff;
  }

  .wirewolf-bg{
    background-image: linear-gradient(to right, #f54001 , #efb923) !important;
    color: white !important;
    /* background: transparent !important; */
  }

  .featureicons{
    height: 130px;
    width: 130px;
  float: left;
  /* margin-left:15px; */
  }

  .leftright{
    position: relative;
    animation: mymove 5s infinite;
    animation-direction:alternate-reverse;
  }

  @keyframes mymove {
  from {left: 200px;}
  to {left: 0px;}
}

</style>
