import { createRouter, createWebHistory } from 'vue-router';

import home from '../components/home/home.vue';
import about from '../components/about/about.vue';
import account from '../components/account/create_account.vue';
import success_account from '../components/account/success_account.vue';
import products from '../components/products/products.vue';
import motel from '../components/products/motel.vue';
import restoretail from '../components/products/restoretail.vue';
import plans from '../components/plans/plan.vue';
import appointment from '../components/appointment/appointment.vue';
import appointment_complete from '../components/appointment/appointment_complete.vue';

export default new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/', name: 'index', component: home,
        },
        {
            path: '/home', name: 'home', component: home
        },
        {
            path: '/about', name: 'about', component: about
        },
        {
            path: '/account', name: 'account', component: account
        },
        {
            path: '/account_complete', name: 'account_complete', component: success_account
        },
        {
            path: '/products', name: 'products', component: products
        },
        {
            path: '/motel', name: 'motel', component: motel
        },
        {
            path: '/restoretail', name: 'restoretail', component: restoretail
        },
        {
            path: '/plans', name: 'plans', component: plans
        },
        {
            path: '/appointment', name: 'appointment', component: appointment
        },
        {
            path: '/appointment_complete', name: 'appointment_complete', component: appointment_complete
        }
    ]
});
