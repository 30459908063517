<template>
    <div>
        <div id="create-account" class="section-bg">
        &nbsp;
        </div>
        <section id="create-account-section" class="contact">
        <div class="container">
            <div class="col-md-12" v-if="this.subs!='free'">
                
            </div>

            <!-- Progress-->
            <div  data-aos="fade-up">
                <div class="steps">
                    <div class="steps-header">
                        <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="'width: '+this.progress+'%'" aria-valuenow="40" aria-valuemin="0"
                            aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="steps-body">
                        <div v-bind:class="(this.stageActive>=0)?'step step-active':'step'">
                            <!-- <span class="step-indicator">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                </svg>
                            </span> -->
                            <span class="step-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                            </svg>
                            </span>
                            Personal Information
                        </div>
                        <div v-bind:class="(this.stageActive>=1)?'step step-active':'step'">
                            <!-- <span class="step-indicator">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                </svg>
                            </span> -->
                            <span class="step-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                </svg>
                            </span>
                            Company Details
                        </div>
                        <div v-bind:class="(this.stageActive>=2)?'step step-active':'step'">
                            <span class="step-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </span>
                            Account
                        </div>
                        <!-- <div v-bind:class="(this.stageActive>=3)?'step step-active':'step'">
                            <span class="step-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                                <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                                </svg>
                            </span>
                            Card Details
                        </div> -->
                    </div>
                </div>
                <div class="stepcontent">
                    <!-- PERSONAL -->
                    <div v-bind:class="(this.stageActive==0)?'form-control':'form-control hide'" style="padding-top: 50px;">
                        <div  class="php-email-form">
                            <div class="row">
                                <div class="offset-lg-3 col-lg-6 col-md-12">
                                    <div role="form">
                                        <div class="form-group">
                                            <label>First Name </label> <span class="require">*</span>
                                            <input type="text" class="form-control" v-model="fname" id="fname" placeholder="example: Juan">
                                        </div>
                                        <div class="form-group">
                                            <label>Middle Name </label> <span class="optional">(Optional)</span>
                                            <input type="text" class="form-control" v-model="mname" id="mname" placeholder="example: Dela">
                                        </div>
                                        <div class="form-group">
                                            <label>Last Name </label> <span class="require">*</span>
                                            <input type="text" class="form-control" v-model="lname" id="lname" placeholder="example: Cruz">
                                        </div>
                                        <div class="form-group">
                                            <label>Contact Number </label> <span class="require">*</span>
                                            <input type="text" class="form-control" v-model="c_number" name="contactnumber" id="cnumber" placeholder="example: 09080808080" required>
                                        </div>
                                        <!-- <div class="form-group">
                                            <input type="text" class="form-control" v-model="company" name="contactnumber" id="cnumber" placeholder="Company or Business Name" required>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COMPANY -->
                    <div v-bind:class="(this.stageActive==1)?'form-control':'form-control hide'" style="padding-top: 50px;">
                        <div  class="php-email-form">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div role="form">
                                        <div class="form-group">
                                            <label>Company Name </label> <span class="require">*</span>
                                            <input type="text" class="form-control" v-model="comp_name" placeholder="example: Company A">
                                        </div>
                                        <div class="form-group">
                                            <label>Business Type </label> <span class="require">*</span>
                                            <select class="form-control" v-model="business_type">
                                                <option value="">-- Select Business Type --</option>
                                                <option v-for="(type, index) in listCompType.result" :key="index" :value="type.id"> 
                                                    {{type.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Company Email </label> <span class="require">*</span>
                                            <input type="email" class="form-control" v-model="comp_email" placeholder="company_a@email.com" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div role="form">
                                        <!-- <div class="form-group">
                                            <label>Country </label> <span class="require">*</span>
                                            <input type="text" placeholder="example: PH" list="country" class="form-control" v-model="selcountry" @change="changeCountry()">
                                            <datalist id="country">
                                                <option v-for="(type, index) in countryList.data" :key="index" :value="type.iso2"> 
                                                    {{type.name}}
                                                </option>
                                            </datalist>
                                        </div> -->
                                        <div class="form-group">
                                            <label>Country </label> <span class="require">*</span>
                                            <select class="form-control" v-model="selcountry" @change="changeCountry()">
                                                <option value="">-- Select Country --</option>
                                                <option v-for="(type, index) in countryList.result" :key="index" :value="type.id"> 
                                                    {{type.name}}
                                                </option>
                                            </select>
                                        </div>        
                                        <div class="form-group">
                                            <label>State </label> <span class="require">*</span>
                                            <select class="form-control" v-model="selstate" @change="changeState()">
                                                <option value="">-- Select State --</option>
                                                <option v-for="(type, index) in stateList.result" :key="index" :value="type.id"> 
                                                    {{type.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label>State </label> <span class="require">*</span>
                                            <input list="state" @change="changeState()" class="form-control" placeholder="example: NCR" v-model="selstate">
                                            <datalist id="state">
                                                <option v-for="(type, index) in stateList.data" :key="index" :value="type.iso2"> 
                                                    {{type.name}}
                                                </option>
                                            </datalist>
                                        </div> -->
                                        <!-- <div class="form-group">
                                            <label>City </label> <span class="require">*</span>
                                            <input list="city" class="form-control" placeholder="example: Pasig" v-model="selcity">
                                            <datalist id="city">
                                                <option v-for="(type, index) in cityList.data" :key="index" :value="type.name"> 
                                                    
                                                </option>
                                            </datalist>
                                        </div> -->
                                        <div class="form-group">
                                            <label>City </label> <span class="require">*</span>
                                            <select class="form-control" v-model="selcity">
                                                <option value="">-- Select City --</option>
                                                <option v-for="(type, index) in cityList.result" :key="index" :value="type.id"> 
                                                    {{type.name}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label>Address </label> <span class="require">*</span>
                                            <input type="text" class="form-control" v-model="address" placeholder="example: Street Compound Brgy. San Juan" required>
                                        </div>
                                        <!-- <div class="form-group">
                                            <input type="text" class="form-control" v-model="fname" placeholder="ZIP Code" required>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ACCOUNT -->
                    <div v-bind:class="(this.stageActive==2)?'form-control':'form-control hide'" style="padding-top: 50px;">
                        <div  class="php-email-form">
                            <div class="row">
                                <div class="offset-lg-3 col-lg-6 col-md-12">
                                    <div class="form-group">
                                            <label>Email </label> <span class="require">*</span>
                                        <input type="email" class="form-control" v-model="email" placeholder="example: account@email.com">
                                    </div>
                                    <div role="form">
                                        <div class="form-group">
                                            <label>Password </label> <span class="require">*</span>
                                            <input type="password" class="form-control" v-model="password" placeholder="example: ******">
                                        </div>
                                        <div class="form-group">
                                            <label>Re-type Password </label> <span class="require">*</span>
                                            <input type="password" class="form-control" v-model="rePassword" placeholder="example: ******">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CARD -->
                    <!-- <div v-bind:class="(this.stageActive==3)?'form-control':'form-control hide'" style="padding-top: 50px;">
                        <div  class="php-email-form">
                            <div class="row">
                                <div class="offset-lg-3 col-lg-6 col-md-12">
                                    <div role="form">
                                        <div class="form-group">
                                            <label>Card Number </label>&nbsp;
                                            <span v-if="this.subs != 'free'" class="require">*</span>
                                            <span v-else class="optional">(Optional)</span>
                                            <input type="text" class="form-control" v-model="card_number" placeholder="example: 123456789012">
                                        </div>
                                        <div class="form-group">
                                            <label>Name of Card </label>&nbsp;
                                            <span v-if="this.subs != 'free'" class="require">*</span>
                                            <span v-else class="optional">(Optional)</span>
                                            <input type="text" class="form-control" v-model="card_name" placeholder="example: Juan Dela Cruz">
                                        </div>
                                        <div class="form-group">
                                            <label>Expiration Date </label>&nbsp;
                                            <span v-if="this.subs != 'free'" class="require">*</span>
                                            <span v-else class="optional">(Optional)</span>
                                            <input type="text" class="form-control" v-model="exp_date" placeholder="example: 11/2025">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="d-sm-flex flex-wrap justify-content-between align-items-center text-center pt-4">
                    <button @click="prevStage()" id="btn-prev" class="btn btn-primary btn-sm mt-2 p-2">Back</button>
                    <div v-if="this.stageActive == 3 && this.subs == 'free'">
                        <button @click="saveAccount()" id="btn-next" class="btn btn-primary btn-sm mt-2 p-2">Skip And Finish</button>
                    </div>
                    <div v-else-if="(this.stageActive == 2)">
                        <button @click="saveAccount()" id="btn-next" class="btn btn-primary btn-sm mt-2 p-2">Finish</button>
                    </div>
                    <div v-else>
                        <button @click="nextStage()" id="btn-next" class="btn btn-primary btn-sm mt-2 p-2">Next</button>
                    </div>
                </div>
            </div>

            <!-- <div class="section-title" data-aos="fade-up">
            <h2>Create Account</h2>
            </div> -->
            <!-- <div  class="php-email-form">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div role="form">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="fname" id="fname" placeholder="First Name" required>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="mname" id="mname" placeholder="Middle Name" required>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="lname" id="lname" placeholder="Last Name" required>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="Email" required>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="c_number" name="contactnumber" id="cnumber" placeholder="Contact Number" required>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="company" name="contactnumber" id="cnumber" placeholder="Company or Business Name" required>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div role="form">
                            <div class="form-group">
                                <input type="password" class="form-control" v-model="password" name="email" id="email" placeholder="Password" required>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" v-model="rePassword" name="contactnumber" id="cnumber" placeholder="Re-type Password" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" v-if="this.subs=='free'">
                        <div class="text-center"><button type="submit" @click="saveAccount()">Start Trial</button></div>
                    </div>
                    <div class="col-md-12" v-else>
                        <div class="text-center"><button type="submit" >Next</button></div>
                    </div>
                </div>
            </div> -->
        </div>
        </section>
    </div>
</template>
<style scoped>
    .hide{
        display: none;
    }
    .optional{
        font-size: 12px;
        color: #a7a3a3;
    }
    .require{
        color: red;
    }
</style>
<script>

  import accountService from '../../service/webservice'
//   import Swal from 'sweetalert2'
  export default {
      data() {
          return {
            type:this.$route.query.type,
            categ:this.$route.query.categ,
            product:this.$route.query.product,
            subs:this.$route.query.subscription,
            // PERSONAL
            fname: "",
            mname: "",
            lname: "",
            c_number: "",
            // ACCOUNT
            email: "",
            password: "",
            rePassword: "",
            // COMPANY
            comp_name: "",
            business_type: "",
            comp_email: "",
            selcountry: "",
            selstate: "",
            selcity: "",
            countryList: [],
            stateList: [],
            cityList: [],
            address: "",
            // CARD DETAILS
            // card_number: "",
            // card_name: "",
            // exp_date: "",
            // ----------
            progress: "25",
            arrStep: {
                0:"personal",
                1:"company",
                2:"account",
                3:"card" 
            },
            stageActive: 0,
            // listCompType: {
            //     id: '',
            //     name: ''
            // },
            listCompType: [],
            selectedcount: [],
            personal_information: {
            firstname: '',
            lastname: '',
            middlename: '',
            mobile_number: ''
        },
        // Business Details
        business_details: {
            business_name: '',
            business_address: '',
            business_type_id: null,
            country_id: null,
            city_id: null,
            state_id: null
        },
        // Account Information
        account_information: {
            email: '',
            facebook_id: '',
            google_id: '',
            is_parent: 1,
            division_id: 1,
            role_id: 1
        },
          }
      },
      created(){
          
      },
      mounted(){
          this.getCompList()
          this.subscriptionStep()
          this.getListCountry()
      },
      methods: {
            getCompList(){
                accountService.getCompList()
                .then(response=>{
                    this.listCompType = response.data
                })
                .catch(error=> console.error(error))
            },
          nextStage(){
              if( (parseInt(this.progress) + parseInt(25)) <= 100 ){
                  if( this.validateField() == 1 ){
                        this.progress = Math.ceil(parseInt(this.progress) + parseInt(25));
                        this.subscriptionStep()
                  }
              }
          },
          prevStage(){
              if( (parseInt(this.progress) - parseInt(25)) > 0 ){
                //   if( this.validateField() == 1 ){
                        this.progress = Math.ceil(parseInt(this.progress) - parseInt(25));
                        this.subscriptionStep()
                //   }
              }
          },
          subscriptionStep(){
              this.stageActive = parseInt(this.progress/25)-parseInt(1)
          },
          validateField(){
              var stat = 1;
              if( this.stageActive == 0 ){ // -- PERSONAL
                  if( this.fname == '' ){
                      stat = 0;
                  }
                  if( this.lname == '' ){
                      stat = 0;
                  }
                  if( this.c_number == '' ){
                      stat = 0;
                  }
              } else if( this.stageActive == 1 ){ // -- COMPANY
                if( this.comp_name == '' ){
                    stat = 0;
                }
                if( this.business_type == '' ){
                    stat = 0;
                }
                if( this.comp_email == '' ){
                    stat = 0;
                }
                if( this.selcountry == '' ){
                    stat = 0;
                }
                if( this.selstate == '' ){
                    stat = 0;
                }
                if( this.selcity == '' ){
                    stat = 0;
                }
                if( this.address == '' ){
                    stat = 0;
                }
              } else if( this.stageActive == 2 ){ // -- ACCOUNT
                if( this.email == '' ){
                    stat = 0;
                }
                if( this.password == '' ){
                    stat = 0;
                }
                if( this.rePassword == '' ){
                    stat = 0;
                }

                if( this.password != this.rePassword ){
                    this.$swal({
                        title: 'Password Mismatch',
                        text: 'Please check your password carefully',
                        icon: 'error',
                        position: 'top',
                        timer: 1500,
                        showConfirmButton: false
                    });
                    stat = 2;
                }

              } else { // -- CARD
                if( this.subs != 'free' ){
                    if( this.card_number == '' ){
                        stat = 0;
                    }
                    if( this.card_name == '' ){
                        stat = 0;
                    }
                    if( this.exp_date == '' ){
                        stat = 0;
                    }
                }
              }
              if( stat == 0 ){
                this.$swal({
                    title: 'Warning',
                    text: 'Complete all required fields',
                    icon: 'warning',
                    position: 'top',
                    timer: 1500,
                    showConfirmButton: false
                });
              }
            //   return 1;
              return stat;
          },
          saveAccount(){
            //   alert(this.fname)
            // var xsubs = 1;
            // if( this.subs == 'free' ){
            //     xsubs = 0;
            // }
            //   var xdata = {
                
            //         // type: this.type, // -- restaurant/retail
            //         // categ: this.categ, // -- lite/enterprise
            //         // product: this.product, // -- wirewolf
            //         // is_subs: xsubs, // 1/0
            //         // PERSONAL
            //         fname: this.fname,
            //         mname: this.mname,
            //         lname: this.lname,
            //         c_number: this.c_number,
            //         // ACCOUNT
            //         email: this.email,
            //         password: this.password,
            //         // COMPANY
            //         comp_name: this.comp_name,
            //         business_type: this.business_type,
            //         comp_email: this.comp_email,
            //         selcountry: this.selcountry,
            //         selstate: this.selstate,
            //         selcity: this.selcity,
            //         address: this.address,
            //         // CARD DETAILS
            //         // card_number: this.card_number,
            //         // card_name: this.card_name,
            //         // exp_date: this.exp_date,
            //   }
              var payload = {
                personal_information: JSON.stringify({
                    firstname: this.fname,
                    lastname: this.lname,
                    middlename: this.mname,
                    mobile_number: this.c_number
                }),
                business_details: JSON.stringify({
                    business_name: this.comp_name,
                    business_address: this.address,
                    business_type_id: this.business_type,
                    country_id: this.selcountry,
                    city_id: this.selcity,
                    state_id: this.selstate
                }),
                account_information: JSON.stringify({
                    email: this.email,
                    password: this.password,
                    facebook_id: '',
                    google_id: '',
                    is_parent: 1,
                    division_id: 1,
                    role_id: 1
                })
            }              
              console.log(payload)
            accountService.saveClient(payload)
                .then(response=>{
                    console.log(response)
                    window.location.href="/account_complete"
                })
                .catch(error=> console.error(error))
          },
          getListCountry(){
                accountService.getCountryList()
                .then(response=>{
                    this.countryList = response.data
                    // console.log(response.data.result)
                })
                .catch(error=> console.error(error))
            },          
        //   getListCountry(){
        //       accountService.getCountryList()
        //         .then(response=>{
        //             // this.countryList = response
        //             // console.log(response.data)
        //             if(response.data.success){

        //             this.countryList = response.config.data
        //             console.log(this.countryList);
        //             // response.data.result.forEach((value) => {
        //             //     this.countryList.push({
        //             //         text: value.name,
        //             //         value: value.id
        //             //     });
        //             // });
        //         }
        //         })
        //         .catch(error=> console.error(error))
        //   },
          changeCountry(){
              this.selstate = ""
              this.selcity = ""
            //   console.log(this.selcountry)
              this.getListCountry()
              this.getStateList(this.selcountry)
          },
          getStateList(id){
                // console.log(id)
                // `accountService.getCountryList(id)
                // .then(response=>{
                //     this.selectedcount = response.data.result
                // })`
                // console.log(this.selectedcount['id'])
              accountService.getStateList(id)
                .then(response=>{
                    // console.log(response.data)
                    this.stateList = response.data
                    // console.log(response.data)
                    // console.log(this.stateList)
                })
                .catch(error=> console.error(error))
          },
          changeState(){
              this.selcity = ""
              console.log(this.selstate)
              this.getCityList(this.selstate)
          },
          getCityList(id){
              accountService.getCityList(id)
                .then(response=>{
                    console.log(response.data)
                    this.cityList = response.data
                    // console.log(response.data)
                    // console.log(this.cityList)
                })
                .catch(error=> console.error(error))
          }
      }
  }
</script>