import { createStore } from 'vuex';

export default new createStore({
    state () {
      return {
        header: ""
      }
    },
    getters: {
        fetchHeader(state){
            return state.header;
        }
    }
  });