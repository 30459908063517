import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {},
});

export default{
    // getClient(){
    //     return apiClient.post('/api/account/get')
    // },
    saveClient(payload){
        return apiClient.post('/register/account',payload);
    },
    saveAppointment(data){
        return apiClient.post('/appointment/save',data);
    },
     getCompList(){
        return apiClient.post('/resources/fetchnerdvanabusinesstypeinformation');
    },
    getCountryList(){
        return apiClient.post('/resources/fetchnerdvanaworldinformation');
    },
    getStateList($country_code){
        var payload = {
            country_id: $country_code
        }
        return apiClient.post('/resources/fetchstateinformation',payload);
    },
    getCityList($state_code){
        var payload = {
            state_id: $state_code
        }
        return apiClient.post('/resources/fetchcitiesinformation',payload);
    }
    // getCompList(){
    //     return apiClient.post('/api/resources/getCompType');
    // },
    // getCountryList(){
    //     return apiClient.get('/api/address/country/all');
    // },
    // getStateList($country_code){
    //     return apiClient.get('/api/address/state/'+$country_code);
    // },
    // getCityList($country_code,$state_code){
    //     return apiClient.get('/api/address/city/'+$country_code+'/'+$state_code);
    // }
}