<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">

      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-12 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">We Simplify the way you do Business.</h1>
            <!-- <h2 data-aos="fade-up" data-aos-delay="400">Goals, Support and Security</h2> -->
            <h4 data-aos="fade-up" data-aos-delay="400">Get ready to upgrade your business game with Positive POS Solution by Nerdvana! Say goodbye to boring and complicated management, and hello to a user-friendly software that takes the hassle out of running your restaurant or retail store. With Positive POS, you'll have more time to do what really matters - create unforgettable experiences for your customers and skyrocket your biz growth!
            </h4>
            <div data-aos="fade-up" data-aos-delay="800">
              <!-- <a href="#products" class="btn-get-started scrollto">View Our Products</a> -->
              <span>The First <b>BIR-Accredited</b> Cloud-based Android POS System in the <b>Philippines</b></span>
            </div>
          </div>

          <div class="col-lg-1"></div>
          <div class="col-lg-5  col-md-8 col-sm-12 hero-img" data-aos="fade-left" data-aos-delay="200">
            <!-- <img src="../../assets/img/hero-img.png" class="img-fluid animated" alt=""> -->
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/DdFKbyg4r2E?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1" frameborder="0" allowfullscreen></iframe> -->
            <!-- <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FHrwXwNonkTEABIRnsysOH4%2FUntitled%3Fnode-id%3D706%253A525%26scaling%3Dscale-down%26page-id%3D0%253A1" allowfullscreen></iframe> -->
            <iframe height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FHrwXwNonkTEABIRnsysOH4%2FUntitled%3Fnode-id%3D706%253A525%26scaling%3Dscale-down%26page-id%3D0%253A1%26hide-ui%3D1" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= Clients Section ======= -->
      <section id="clients" class="clients clients">
        <div class="container">
          <h2>Trusted by local customers, soon expanding across other countries.</h2>
          <div class="row">
            <div class="col-lg-0"></div>
            <div class="col-lg-3 col-md-4 col-6">
              <img src="../../assets/img/clients/huit.png" class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="200">
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <img src="../../assets/img/clients/yoshi.png" class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100">
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <img src="../../assets/img/clients/vc.png" class="img-fluid" alt="" data-aos="zoom-in">
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <img src="../../assets/img/clients/kkk.png" class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100">
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <!-- <img src="../../assets/img/clients/fookyuen.png" class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="200"> -->
              <!-- <a href="" style="color:black"><u>Watch their stories</u></a> -->
            </div>

            <div class="col-lg-0"></div>

          </div>
          <div class="row">
            <div class="col-lg-0"></div>
            <div class="col-lg-3 col-md-4 col-6"></div>

            <div class="col-lg-2 col-md-4 col-6"></div>

            <div class="col-lg-2 col-md-4 col-6">
              <a href="#" style="color:black"><u>Watch their stories</u></a>
            </div>

            <div class="col-lg-2 col-md-4 col-6">
            </div>

            <div class="col-lg-3 col-md-4 col-6">
            </div>

            <div class="col-lg-0"></div><br><br>

          </div>


        </div>
      </section><!-- End Clients Section -->

      <!-- ======= About Us Section ======= -->
      <!-- <div id="about">
        &nbsp;
      </div>
      <section id="section-about" class="about">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>About Us</h2>
          </div>

          <div class="row content">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
             
              <ul>
                <li><i class="ri-check-double-line"></i> Track Business Performance</li>
                <li><i class="ri-check-double-line"></i> View your Analytics and Critical Stocks</li>
                <li><i class="ri-check-double-line"></i> Profitable and Fast Moving Products</li>
              </ul>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
              <p style="text-text-align: justify;">
                The country's first and only accredited cloud-based POS system.
                At Nerdvana, we guarantee that we have the best manpower on hand to give you with the best IT services for your company. Our people, in collaboration with our best programmers and designers, will create customized, user-friendly systems. Our software can provide you with all of the necessary and vital information you require at a glance, at any time.
                Our devoted team ensures that our clients receive support and assistance 24 hours a day, seven days a week, with guaranteed speed and efficiency. As a result, we are able to effectively respond to operational concerns. We are quite proud of the fact that our systems are Filipino-designed and developed, they can be installed quickly and easily compared to other licensed software.
              </p>
            </div>
          </div>

        </div>
      </section> -->
      <!-- End About Us Section -->

      <!-- ======= Counts Section ======= -->
      <!-- <section id="counts" class="counts">
        <div class="container">

          <div class="row">
            <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
              <img src="../../assets/img/home/about.jpg" style="width: 400px" alt="" class="img-fluid">
            </div>

            <div class="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
              <div class="content d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-md-4 d-md-flex align-items-md-stretch">
                    <div class="count-box">
                      <i class="bi bi-people-fill"></i>
                      <span data-purecounter-start="0" data-purecounter-end="65" data-purecounter-duration="1" class="purecounter"></span>
                      <p><strong>Teamwork</strong> influence employees growth through collective ideas that bring the best result.</p>
                    </div>
                  </div>

                  <div class="col-md-4 d-md-flex align-items-md-stretch">
                    <div class="count-box">
                      <i class="bi bi-arrow-up-right"></i>
                      <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="1" class="purecounter"></span>
                      <p><strong>Innovation</strong> recalibrating issues to discover practical solutions</p>
                    </div>
                  </div>

                  <div class="col-md-4 d-md-flex align-items-md-stretch">
                    <div class="count-box">
                      <i class="bi bi-clock"></i>
                      <span data-purecounter-start="0" data-purecounter-end="18" data-purecounter-duration="1" class="purecounter"></span>
                      <p><strong>Agility</strong> think critically and act quickly to provide immediate solution</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section> -->
      <!-- End Counts Section -->

      <!-- ======= Services Section ======= -->
      <div id="services"  class="section-bg">
        &nbsp;
      </div>
      <section id="section-services" class="services section-bg">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <b><font size="8">WHAT </font> <font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font><font size="8"> CAN DO FOR YOU?</font></b>
            <!-- <img src="../../assets/img/logo/positive_text.png" class="img-fluid" alt="" width="170px"> -->
            <p>Our Business Performance in Real Time</p>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <!-- <div class="icon"><i class="bi bi-bar-chart-line"></i></div> -->
                <h4 class="title"><a href="">Inventory Intelligence</a></h4>
                <p class="description">Keep track of your stock levels, sales trends, and supplier basic information with our Inventory Management service. Our easy-to-use interface helps you stay on top of your inventory, so you can make informed decisions about when to order more stock, and which products are selling well.</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <!-- <div class="icon"><i class="bi bi-graph-up"></i></div> -->
                <h4 class="title"><a href="">Checkout Champ</a></h4>
                <p class="description">Our POS Transactions service helps you take payments quickly and securely. It's easy to use, so you can quickly complete sales, and we offer different payment options so your customers can choose how they want to pay.</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <!-- <div class="icon"><i class="bi bi-person-lines-fill"></i></div> -->
                <h4 class="title"><a href="">Trend Tracker</a></h4>
                <p class="description">Our Advanced Reports and Analytics service lets you track and analyze trends in your sales performance. With real-time data, comprehensive reports, and trend analysis, you'll be able to identify patterns and adjust your strategies to stay ahead of the curve. Send email copy of the report. You can also select specific column to display.</p>
              </div>
            </div>

            <div style="margin-bottom: 12px;"></div>

            <div class="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" style="width: 100%;" data-aos="fade-up" data-aos-delay="400">
                <!-- <div class="icon"><i class="bi bi-calendar-x"></i></div> -->
                <h4 class="title"><a href="">Business Brain</a></h4>
                <p class="description">With our Back Office service, you'll be able to manage your business operations with ease. From employee and shift scheduling to inventory management, it's your one-stop-shop for everything you need to run your business smoothly.</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <!-- <div class="icon"><i class="bi bi-exclamation-octagon-fill"></i></div> -->
                <h4 class="title"><a href="">HR Hero</a></h4>
                <p class="description">Managing employees can be tricky, but with our Employee Management service, you'll have everything you need to stay on top of your staff's performance, timekeeping, and more. Whether you have 1 or 100 employees, our service can help you streamline your HR tasks.</p>
              </div>
            </div>

          </div>

        </div>
      </section>
      <!-- End Services Section -->

      <!-- ======= More Services Section ======= -->
      <!-- <section id="more-services" class="more-services">
        <div class="container">

          <div class="row">
            <div class="col-md-6 d-flex align-items-stretch">
              <div class="card" style='background-image: url("../../assets/img/more-services-1.jpg");' data-aos="fade-up" data-aos-delay="100">
                <div class="card-body">
                  <h5 class="card-title"><a href="">Lobira Duno</a></h5>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div class="card" style='background-image: url("../../assets/img/more-services-2.jpg");' data-aos="fade-up" data-aos-delay="200">
                <div class="card-body">
                  <h5 class="card-title"><a href="">Limere Radses</a></h5>
                  <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem.</p>
                  <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
                </div>
              </div>

            </div>
            <div class="col-md-6 d-flex align-items-stretch mt-4">
              <div class="card" style='background-image: url("../../assets/img/more-services-3.jpg");' data-aos="fade-up" data-aos-delay="100">
                <div class="card-body">
                  <h5 class="card-title"><a href="">Nive Lodo</a></h5>
                  <p class="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores.</p>
                  <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch mt-4">
              <div class="card" style='background-image: url("../../assets/img/more-services-4.jpg");' data-aos="fade-up" data-aos-delay="200">
                <div class="card-body">
                  <h5 class="card-title"><a href="">Pale Treda</a></h5>
                  <p class="card-text">Nostrum eum sed et autem dolorum perspiciatis. Magni porro quisquam laudantium voluptatem.</p>
                  <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section> -->
      <!-- End More Services Section -->

      <!-- ======= Features Section ======= -->
      <div id="products">
        &nbsp;
      </div>
      <section id="products-section" class="features">
        <div>

          <!-- <div class="section-title" data-aos="fade-up">
            <h2>Products</h2>
            <p><span class="text-gradiant">Wirewolf</span> gives a <strong>innovative solutions</strong>, <strong>creative arrangements</strong>, and help to <strong>simplify</strong> your <strong>business' cycle of advantage</strong>.</p>
          </div> -->

          <div class="row">
            <div class="col-lg-3 col-md-6" data-aos="fade-up">
              <!-- <div class="icon-box"> -->
                <!-- <div class="imgcontainer">
                  <img src="../../assets/img/home/pos.png" alt="">
                </div> -->
                <h2 style="margin-left: 50px;margin-top: 50px;">Upgrade and gain an advantage for your business with our products.</h2>
              <!-- </div> -->
            </div>
            <div class="col-lg-3 col-md-6" data-aos="fade-up">
              <div class="icon-box">
                <div class="imgcontainer">
                  <!-- <img src="../../assets/img/home/pos.png" alt=""> -->
                  <a href="motel">
                  <img src="../../assets/img/home/product/hotel.png" alt="">
                </a>
                </div>
                <!-- <h3><a>Motel</a></h3> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box">
                <div class="imgcontainer">
                  <!-- <img src="../../assets/img/home/product/retail.jpg" alt=""> -->
                  <a href="products">
                  <img src="../../assets/img/home/product/retail.png" alt="">
                  </a>
                </div>
                <!-- <h3><a>Retail</a></h3> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="icon-box">
                <div class="imgcontainer">
                  <!-- <img src="../../assets/img/home/product/resto.jpg" alt=""> --><br>
                  <a href="restoretail">
                  <img src="../../assets/img/home/product/Retail-Resto-V2.png" alt="">
                  </a>
                </div>
                <!-- <h3><a>Restaurant</a></h3> -->
              </div>
            </div>
          </div>
          <!-- <br><br><br><br>
          <center>
            <div>
              <a href="products" class="btn-get-started">Explore More</a>
            </div>
          </center> -->

        </div>
      </section><!-- End Features Section -->

      <!-- ======= Testimonials Section ======= -->
      <!-- <section id="testimonials" class="testimonials section-bg">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Testimonials</h2>
            <p>Magnam dolores commodi suscipit eum quidem consectetur velit</p>
          </div>

          <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div class="swiper-wrapper">

              <div class="swiper-slide">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <img src="../../assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <img src="../../assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <img src="../../assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <img src="../../assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <img src="../../assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div>
      </section> -->
      <!-- End Testimonials Section -->

      <!-- ======= Portfolio Section ======= -->
      <!-- <section id="portfolio" class="portfolio">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Portfolio</h2>
            <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem</p>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="200">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">All</li>
                <li data-filter=".filter-app">App</li>
                <li data-filter=".filter-card">Card</li>
                <li data-filter=".filter-web">Web</li>
              </ul>
            </div>
          </div>

          <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="400">

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>App 1</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>App 2</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 2</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 2</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>App 3</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 1</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 3</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="../../assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="../../assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section> -->
      <!-- End Portfolio Section -->

      <!-- ======= Team Section ======= -->
      <!-- <div id="team" class="section-bg">
        &nbsp;
      </div>
      <section id="sectionteam" class="team section-bg">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Team</h2>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
          <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Sir_Franc.jpg" max-height="10" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Franc Sarno</h4>
                  <span>CTO</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="200">
                <div class="member-img">
                  <img src="../../assets/img/team/Karl.jpeg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Karl Fernandez</h4>
                  <span>Senior Android Developer</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="200">
                <div class="member-img">
                  <img src="../../assets/img/team/Benjie.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Benjie Cruz</h4>
                  <span>Android Developer</span>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/ronald.png" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Ronald Palisoc</h4>
                  <span>Full Stack Developer</span>
                </div>
              </div>
            </div>
            
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="300">
                <div class="member-img">
                  <img src="../../assets/img/team/Slash.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Slash Dela Cruz</h4>
                  <span>Full Stack Developer</span>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-1"></div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="300">
                <div class="member-img">
                  <img src="../../assets/img/team/Nathaniel.png" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Nathaniel Dela Cruz</h4>
                  <span>Full Stack Developer</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="300">
                <div class="member-img">
                  <img src="../../assets/img/team/axldc.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Axl Rose Dela Cruz</h4>
                  <span>Full Stack Developer</span>
                </div>
              </div>
            </div> 
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/jeff.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Jeffrey Tavera</h4>
                  <span>Quality Assurance Tester</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Chen.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Chen Tapang</h4>
                  <span>Quality Assurance Tester</span>
                </div>
              </div>
            </div>  
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Sir_Jong.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Jon Horace Ireneaus Bagayaua</h4>
                  <span>IT Technical Head</span>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-2"></div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Darwin.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Darwin Pagsaligan</h4>
                  <span>CCTV Specialist</span>
                </div>
              </div>
            </div>  
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Mark.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Mark Anthony Maqui</h4>
                  <span>IT Technical Support</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/kem.jpg" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Dikembe Cordova</h4>
                  <span>IT Technical Support</span>
                </div>
              </div>
            </div> 
          <div class="col-lg-2 col-md-6 d-flex align-items-stretch">
              <div class="member" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src="../../assets/img/team/Janicka.png" class="img-fluid" width="200">
                </div>
                <div class="member-info">
                  <h4>Janicka Duhaylungsod</h4>
                  <span>Admin Officer, Liaison and Research Asst</span>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>

        </div>
      </section> -->
      <!-- End Team Section -->

      <!-- ======= Effective Section ======= -->
      <div id="effective"  class="section-bg">
      <section id="pricing-section" class="pricing section-bg">
        <div class="container">

          <!-- <div class="section-title">
            <h2>Pricing</h2>
            <p>Sit sint consectetur velit nemo qui impedit suscipit alias ea</p>
          </div> -->

          <h1>It's effective to use <font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font></h1>
          <div class="row">

            <!-- <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Business</h3>
                <h4><sup>$</sup>19<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Buy Now</a>
                </div>
              </div>
            </div> -->

            <div class="col-lg-4 col-md-6" style="max-width:fit-content">
              <div class="box" data-aos="zoom-in-right" data-aos-delay="200">

                <img src="../../assets/img/home/effective/flexcam.png" style="width:100%;height:100%;" alt="">
                <div class="btn-wrap">
                  <b>Increased efficiency and speed of transactions:</b>
                  <p>A POS solution allows for faster and more accurate transactions, which can help reduce customer wait times and improve overall customer satisfaction. This can lead to an increase in sales and customer loyalty.</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="box" data-aos="zoom-in-right" data-aos-delay="200">

                <img src="../../assets/img/home/effective/storage.png" style="width:100%;height:100%;" alt="">
                <div class="btn-wrap">
                  <b>Improved inventory management:</b>
                  <p>A POS solution can help track inventory levels in real-time, making it easier to reorder products as needed and avoid stockouts. This can help improve customer satisfaction and reduce the costs associated with carrying excess inventory.</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="box" data-aos="zoom-in-right" data-aos-delay="200">

                <img src="../../assets/img/home/effective/markup.png" style="width:100%;height:100%;" alt="">
                <div class="btn-wrap">
                  <b>Enhanced data collection and analysis:</b>
                  <p>A POS system can collect a wealth of data on sales trends, which can be used to make informed business decisions and improve marketing efforts. This can help increase revenue and improve overall business performance.</p><br>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
    </div>
      <!-- End Effective Section -->


      <!-- ======= Pricing Section ======= -->
      <!-- <div id="pricing"  class="section-bg">
      <section id="pricing-section" class="pricing section-bg">
        <div class="container">

          <h1><font size="8" color="#535353">POS</font><font size="8" color="#FF5A00">itive</font> Mobile POS Solution Subscription Plans</h1>
          <div class="row">

            <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Free</h3>
                <h4><sup>₱</sup>0<span> / month</span><br><br></h4>
                <h5>Get the basic plan without spending a dime. It's the perfect starting point for businesses on a tight budget.</h5>
                <ul>
                  <li>Basic Reports And Analytics</li>
                  <li>Employee Management</li>
                  <li>POS Transactions</li>
                  <li class="na">Advanced Reports and Analytics</li>
                  <li class="na">Back Office</li>
                  <li class="na">Cash Drawer</li>
                  <li class="na">Inventory Management</li>
                  <li class="na">Print </li>
                  <li class="na">Receipt Printing</li>
                  <li class="na">Shift Management</li>
                  <li class="na">Unlimited Users</li>
                  <li class="na">2 FREE MONTHS</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Install Now</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Monthly</h3>
                <h4><sup>₱</sup>1,200<span> / month</span><br><br></h4>
                <h5>This plan is perfect for those who want to take their business to the next level without having a long commitment.</h5>
                <ul>
                  <li>Basic Reports And Analytics</li>
                  <li>Employee Management</li>
                  <li>POS Transactions</li>
                  <li>Advanced Reports and Analytics</li>
                  <li>Back Office</li>
                  <li>Cash Drawer</li>
                  <li>Inventory Management</li>
                  <li>Print </li>
                  <li>Receipt Printing</li>
                  <li>Shift Management</li>
                  <li>Unlimited Users</li>
                  <li class="na">2 FREE MONTHS</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe Now</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
                <h3>Annual</h3>
                <h4><sup>₱</sup>10,000 <br><span> With Free 2 months</span></h4>
                <h5>Want to save money and get even more features? Sign up for the annual plan and get two months free!</h5>
                <ul>
                  <li>Basic Reports And Analytics</li>
                  <li>Employee Management</li>
                  <li>POS Transactions</li>
                  <li>Advanced Reports and Analytics</li>
                  <li>Back Office</li>
                  <li>Cash Drawer</li>
                  <li>Inventory Management</li>
                  <li>Print </li>
                  <li>Receipt Printing</li>
                  <li>Shift Management</li>
                  <li>Unlimited Users</li>
                  <li>2 FREE MONTHS</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe Now</a>
                </div>
              </div>
            </div>
          </div>

          </div>

        </div>
      </section>
    </div> -->
      <!-- End Pricing Section -->

      <!-- ======= F.A.Q Section ======= -->
      <section id="faq" class="faq">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-5">
              <i class="ri-question-line"></i>
              <h4>Business Challenges?</h4>
            </div>
            <div class="col-lg-7">
              <p>
                  As business owners, challenges are unavoidable and your current or lack of POS system may be adding up to it.
              </p>
            </div>
          </div><!-- End F.A.Q Item-->

          <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="col-lg-5">
              <i class="ri-question-line"></i>
              <h4>We have your solution - but we still can do more to tailor-fit your business needs?</h4>
            </div>
            <div class="col-lg-7">
              <p>
                We want to help you maximize your resources.
              </p>
              <p>
                We want to help you grow your business.
              </p>
              <p>
                We want to help you worry less and simplify based on your perceptions and practices.
              </p>
              <p>
                POSitive is customizable to offer you convenience and everything you need and want to know about your business.
              </p>
            </div>
          </div>
          <!-- End F.A.Q Item-->

          <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="col-lg-5">
              <i class="ri-question-line"></i>
              <h4>Can your current system detect or deter FRAUD?, Can you monitor your staff performance and efficiency?,Can you easily manage and oversee table statuses?</h4>
            </div>
            <div class="col-lg-7">
              <p>
                You'll know exactly what’s going on, and will always have an eye on everything, with the use of our system you will create a habit and preferences and adjust your  operations according to your data.
                No need to do  checking or monitoring for controls and security. (imagine you have 1000 transactions/per day), without using any reliable system, you are blinded on how much pilferage you have in a month or in a year.
              </p>
            </div>
          </div><!-- End F.A.Q Item-->

          <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div class="col-lg-5">
              <i class="ri-question-line"></i>
              <h4>Are you trying to balance your inventory by monitoring the customer demand and supply?</h4>
            </div>
            <div class="col-lg-7">
              <p>
                If your reply to the question is YES, then, Nerdvana is here for you. We offer you the best solution in town. 
                All information will be conveniently available to you in real time. Whenever, wherever you are.
              </p>
            </div>
          </div>
          <!-- End F.A.Q Item-->

          <!-- <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
            <div class="col-lg-5">
              <i class="ri-question-line"></i>
              <h4>Tempus quam pellentesque nec nam aliquam sem et tortor consequat?</h4>
            </div>
            <div class="col-lg-7">
              <p>
                Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in
              </p>
            </div>
          </div> -->
          <!-- End F.A.Q Item-->

        </div>
      </section><!-- End F.A.Q Section -->


       <!-- ======= Testimonial Section ======= -->
        <section id="hero" class="d-flex align-items-center">

            <div class="container">
              <!-- <div class="row">
                <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h4 data-aos="fade-up" data-aos-delay="400"><p>
                    "One thing I really love about Positive is that even though I'm not on site, I can already modify whatever I need to do, let's say, all of a sudden there's an out of stock item and we need to replace it immediately with a new item, and I need to upload the new menu price. <b>It's so easy with positive.</b> In five minutes it's already uploaded, it's synced already in the different stores."</p></h4>
                  <div data-aos="fade-up" data-aos-delay="800">
                    <span><b>Joy Morillo </b> Prologue</span>
                  </div>
                </div>
                <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
                  <img src="../../assets/img/testimonials/joy.png" class="img-fluid" alt="">
                </div>
              </div> -->

              <!-- <carousel :items-to-show="1" :breakpoints="breakpoints" :autoplay="2000"> -->
              <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="5000">
                <slide v-for="slide in testimonials" :key="slide">
                  <div class="row">
                <div class="col-lg-12 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
                  <img :src="slide.image_url" class="img-fluid">
                </div>
                <!-- <div class="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h4 data-aos="fade-up" data-aos-delay="400"><p>
                    "{{ slide.testimony }}"</p></h4>
                  <div data-aos="fade-up" data-aos-delay="800">
                    <span><b>{{ slide.name }} </b> {{ slide.company }}</span>
                  </div>
                </div> -->
              </div>
                </slide>
                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>

              

              
            </div>


          </section>

          <!-- <div class="container" style="background-color:#D8D8D8; border-radius: 2em/2em;">
              <div class="row">
                <div class="col-lg-4">
                  <font size="15px">98%</font>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div class="col-lg-4">
                  <font size="15px">138</font>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div class="col-lg-4">
                  <font size="15px">90%</font>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
              </div>
            </div> <br><br> -->
          <!-- ======= Testimonial Section ======= -->

      <!-- ======= Contact Section ======= -->
      <div id="contact" class="section-bg">
        &nbsp;
      </div>
      <section id="contact-section" class="contact">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Contact Us</h2>
          </div>

          <div class="row">

            <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="contact-about">
                <h3>Nerdvana</h3>
                <p>We are here to support and protect your business, in order to do this we simplify the way you do your business.</p>
                <div class="social-links">
                  <a href="https://www.tiktok.com/@nerdvana_17" target="_blank" class="twitter">
                      <BIconTiktok class="mb-2" />
                  </a>
                  <a href="https://www.facebook.com/NerdvanaPOS" target="_blank" class="facebook">
                      <BIconFacebook class="mb-2" />
                  </a>
                  <a href="https://www.instagram.com/nerdvana_pos/?hl=en" target="_blank" class="instagram">
                      <BIconInstagram class="mb-2" />
                  </a>
                  <!-- <a href="#" target="_blank" class="linkedin">
                      <BIconLinkedin class="mb-2" />
                  </a> -->
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
              <div class="info">
                <div>
                  <i class="ri-map-pin-line"></i>
                  <p>Canley #1 1100 Pasig, Philippines.</p>
                </div>

                <div>
                  <i class="ri-mail-send-line"></i>
                  <p>sales@nerdvana.com.ph</p>
                </div>

                <div>
                  <i class="ri-phone-line"></i>
                  <p>(+63) 917-634-2243</p>
                </div>

              </div>
            </div>

            <!-- <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
              
            </div> -->

          </div>

        </div>
      </section><!-- End Contact Section -->

    </main><!-- End #main -->
    
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
export default {
  name: 'home',
  props: {
    msg: String
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination
  },
  data(){
    return {
      images: {
        prod:{
          motel: "pos.png",
          resto: "pos.png",
          retail: "pos.png",
          lite: "pos.png"
        }
      },
      
      testimonials: [{
          // image_url: "http://localhost:8082/img/Prologue-Testimonial.1f67540c.png",
          image_url: "http://nerdvana.com.ph/img/Prologue-Testimonial.1f67540c.png",
          testimony: "One thing I really love about Positive is that even though I'm not on site, I can already modify whatever I need to do, let's say, all of a sudden there's an out of stock item and we need to replace it immediately with a new item, and I need to upload the new menu price. It's so easy with positive. In five minutes it's already uploaded, it's synced already in the different stores.",
          name: "Joy Morillo",
          company: "Prologue",
        },
        {
          // image_url: "http://localhost:8082/img/Huit-Testimonial.9d366a65.png",
          image_url: "http://nerdvana.com.ph/img/Huit-Testimonial.9d366a65.png",
          testimony: "The way we were doing things before, is almost next to impossible to expand the business and achieve what we wanted for this business. Then when we had positive. There was a lot of change in terms of the way we were doing it. POSitive becomes the heart of our business. \n",
          name: "Hernando Pangilinan",
          company: "Huit",
        },
        {
          // image_url: "http://localhost:8082/img/Yoshimeatsu-Testimonial.fdd75d5b.png",
          image_url: "http://nerdvana.com.ph/img/Yoshimeatsu-Testimonial.fdd75d5b.png",
          testimony: "The way we were doing things before, is almost next to impossible to expand the business and achieve what we wanted for this business. Then when we had positive. There was a lot of change in terms of the way we were doing it. POSitive becomes the heart of our business. \n",
          name: "Hernando Pangilinan",
          company: "Huit",
        }],
        
    }
  },
  created(){
    this.$store.state.header = "main";
  },
  mounted(){
    console.log(this.$store.getters.fetchHeader);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* Defualt */
  .member{
    width: 100%;
  }
  
  .member .member-img img{
    height: 200px !important;
  }

  .features .icon-box{
    display: block !important;
    /* margin: auto;
    width: 100% ; */
    text-align: center;
    background: transparent !important;
  }

  .icon-box img{
    width: 100%;
    height: 100%;
    /* position: relative; */
    /* top: -5px;
    left: -5px; */
    /* overflow: auto; */
  }

  .imgcontainer{
    /* position: relative; */
    margin: auto;
    margin-bottom: 20px;
    width: 300px;
    height: 300px;
    border-radius: 10%;
    padding: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }

  .text-gradiant{
      /* Fallback: Set a background color. */
    background-color: red;
    font-weight: bold;
    /* Create the gradient. */
    background-image: linear-gradient(to right, #f54001 , #efb923);
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  .btn-get-started{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #3498db;
    border: 2px solid #3498db;
  }

  .carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  display: none;
}

/* disable next on last page */
/* .carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
} */
</style>
