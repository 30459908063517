<template>
    <div>
        <div id="create-account" class="section-bg">
        &nbsp;
        </div>
        <section id="create-account-section" class="contact">
        <div class="container">
            <div class="section-title" data-aos="fade-up">
            <h2>Appointment</h2>
            </div>
        </div>
        </section>
    </div>
</template>
<script>
  export default {
      data() {
          return {

          }
      },
      created(){
          
      },
      mounted(){
          
      },
      methods: {
      }
  }
</script>