<template>
  <div>
    <main id="main">

      <div id="contact" class="section-bg">
        &nbsp;
      </div>
      <section id="contact-section" class="contact">
        <div class="container">

          <div class="section-title" data-aos="fade-up">
            <h2>Appointment</h2>
          </div>
          <div class="offset-lg-3 col-lg-6 col-md-12" data-aos="fade-up" data-aos-delay="300">
            <div class="php-email-form">
              <div class="form-group">
                <label>Your Name </label> <span class="require">*</span>
                <input type="text" name="name"  v-model="name" class="form-control" id="name" placeholder="example: Juan Dela Cruz" required>
              </div>
              <div class="form-group">
                <label>Your Email </label> <span class="require">*</span>
                <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="example: email@mail.com" required>
              </div>
              <div class="form-group">
                <label>Company Type </label> <span class="require">*</span>
                <select name="" id="" class="form-control" v-model="type_business">
                  <option value="">Select Company Type</option>
                   <option v-for="(type, index) in listCompType.data" :key="index" :value="type.code"> 
                    {{type.desc}}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <h5 for="">Appointment Schedule</h5>
              </div>
              <div class="form-group">
                <label>Date </label> <span class="require">*</span>
                <input type="date" class="form-control" name="subject" id="subject" v-model="date" placeholder="Company" required>
              </div>
              <div class="form-group">
                <label>Time </label> <span class="require">*</span>
                <input type="time" class="form-control" name="subject" id="subject"  v-model="time" placeholder="Company" required>
              </div>
              <div class="form-group">
                <label>Message </label> <span class="require">*</span>
                <textarea class="form-control" name="message" rows="5" placeholder="Message" v-model="message" required></textarea>
              </div>
              <!-- <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div> -->
              <div class="text-center"><button type="submit" @click="sendAppointment()">Book Appointment</button></div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  </div>
</template>

<script>
import webservice from '../../service/webservice'
export default {
  name: 'about',
  props: {
    msg: String
  },
  data(){
    return{
      type:this.$route.query.type,
      categ:this.$route.query.categ,
      product:this.$route.query.product,
      name: "",
      email: "",
      type_business: "",
      date: "",
      time: "",
      message: "",
      listCompType: []
    }
  },
  created(){
    this.$store.state.header = "product";
    this.getCompList()
  },
  mounted(){
    console.log(this.$store.getters.fetchHeader);
  },
  methods:{
    getCompList(){
      webservice.getCompList()
        .then(response=>{
          this.listCompType = response
          console.log(this.listCompType)
        })
        .catch(error=> console.error(error))
    },
    sendAppointment(){
      if( this.validateFields() == 1 ){
        var xData = {
          name: this.name,
          email: this.email,
          type_business: this.type_business,
          app_date: this.date,
          app_time: this.time,
          message: this.message,
          product: this.product,
          prod_cat: this.categ,
          prod_type: this.type
        }
        webservice.saveAppointment(xData)
          .then(response=>{
            console.log(response)
            window.location.href="/appointment_complete"
          })
          .catch(error=> console.error(error))
      }
    },
    validateFields(){
      var stat = 1;
      if( this.name == '' ){
        stat = 0;
      }
      if( this.email == '' ){
        stat = 0;
      }
      if( this.type_business == '' ){
        stat = 0;
      }
      if( this.date == '' ){
        stat = 0;
      }
      if( this.message == '' ){
        stat = 0;
      }
      if( stat == 0 ){
        this.$swal({
            title: 'Warning',
            text: 'Complete all required fields',
            icon: 'warning',
            position: 'top',
            timer: 1500,
            showConfirmButton: false
        });
      }
      return stat;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #main{
    margin-top: 70px;
  }
  .require{
      color: red;
  }
</style>
