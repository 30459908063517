<template>
  <div>
    <main id="main">
      <!-- ======= Pricing Section ======= -->
      <section id="pricing" class="pricing">
        <div class="container">

          <div class="section-title">
            <h2>SUBSCRIPTION</h2>
            <!-- <p>Sit sint consectetur velit nemo qui impedit suscipit alias ea</p> -->
          </div>

          <div class="row">

            <div class="offset-lg-2 col-lg-4 col-md-6">
              <div class="box" data-aos="zoom-in-right" data-aos-delay="100">
                <h3>Free Trial</h3>
                <h4>1 Month</h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li class="na">Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a v-bind:href="'/account?type='+this.type+'&categ='+this.categ+'&product='+this.product+'&subscription=free'" class="btn-buy">Register</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended" data-aos="zoom-in-left" data-aos-delay="100">
                <h3>Monthly</h3>
                <h4><sup>$</sup>19<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap">
                  <a v-bind:href="'/account?type='+this.type+'&categ='+this.categ+'&product='+this.product+'&subscription=monthly'" class="btn-buy">Register</a>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
      <!-- End Pricing Section -->
    </main>
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  </div>
</template>

<script>
export default {
  name: 'about',
  props: {
    msg: String
  },
  data(){
    return{
      type:this.$route.query.type,
      categ:this.$route.query.categ,
      product:this.$route.query.product
    }
  },
  created(){
    this.$store.state.header = "product";
  },
  mounted(){
    console.log(this.$store.getters.fetchHeader);
    // alert(this.product)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #main{
    margin-top: 70px;
  }
</style>
