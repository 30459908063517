<template>
    <Header/>
    <router-view/>
    <Footer/>
</template>

<script>

import Header from './components/header/header.vue';
import Footer from './components/footer/footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
</style>
